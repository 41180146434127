.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
}

.primaryAction {
  margin-bottom: 2rem;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
}

.secondaryAction {
  text-align: center;
}
