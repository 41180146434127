@import 'styles/partials/variables';

.root {
  display: flex;
  flex-direction: column;
}

.navLink {
  color: white;
  text-decoration: none;
}

.mobileNavLink {
  text-decoration: none;
  color: $primaryDark;
  padding: 0.5rem 1rem;
  flex: 1;
}

.linkActive {
  border-bottom: 2px solid white;
}

.mobileLinkActive {
  background-color: $primaryLightest;
}
