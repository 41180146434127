@import 'styles/partials/variables';
@import 'styles/partials/mixins';

.container {
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
}

.splashContainer {
  min-height: 100vh;
  background-color: $primaryDark;
  background-image: url('../../assets/SplashPattern.svg');
  background-repeat: no-repeat;
  background-size: 100% 30%;
  background-position-y: bottom;

  @include sm {
    background-size: 100% 50%;
    min-height: unset;
  }
}
