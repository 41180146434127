@import 'styles/partials/variables';

.markdown {
  table {
    border-collapse: collapse;
  }

  th,
  td {
    padding: 14px;
    border: 1px solid black;
  }
  th {
    background-color: $primaryLight;
    color: $primaryContrastText;
  }

  p {
    line-height: 1.5;
  }
}
