@import 'styles/partials/variables';
@import 'styles/partials/mixins';

.sideContainer {
  background-color: $primaryMain;
}

.container {
  background-color: $primaryMain;
  background-image: url('../../assets/background.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.gridContainer {
  background-color: $white;
  box-shadow: 0px 10px 60px 0px rgba(40, 40, 42, 0.8);
  border-radius: 24px;
  flex: 1;
  justify-content: center;
}

.divider {
  text-transform: uppercase;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
