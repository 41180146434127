.root {
  position: relative;
}

.input {
  width: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;

  &:invalid:focus {
    opacity: 0;
    width: auto;
    height: auto;
    pointer-events: none;
  }
}

.uploadCard {
  pointer-events: none;
}
