@import 'styles/partials/variables';

.container {
  min-height: 100vh;
  padding: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.childrenContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.childrenContainer > * {
  flex: 1;
}

.footer {
  background-color: $primaryMain;
  padding: 2rem;
  margin-top: auto;
}

.socialLink {
  color: $white;
  text-decoration: none;
}

.socialLink:hover {
  color: $secondaryMain;
  text-decoration: underline;
}

.legalLink,
.moreDetails {
  color: $secondaryLightest;
}

.legalLink:hover {
  color: $secondaryMain;
}
